<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Purchase Airtime 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
              <b-form    @submit.prevent="processtransaction">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col v-for="airtime in userairtime" :key="airtime.id" cols="6" md="3" class="mb-1 ">
                                <b-img :style="addstyle(airtime.networkcode)" @click="selectitem(airtime.networkcode)" thumbnail size="70px" fluid :src="mainurl + airtime.networkimage" /></b-col>
    
                        </b-row>
                  
    
                        <b-form-group label="Phone Number" label-for="phone">
                            <b-form-input v-model="phone" id="phone" size="lg" placeholder="Phone Number" />
                        </b-form-group>
                          <b-form-group label="Check the box below if it's a ported number" label-for="ported">
                        <b-form-checkbox
                          v-model="ported"
                          id="ported"
                          value="yes"
                        >
                        
                          Ported number?
                        </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Amount" label-for="amount">
                            <b-form-input v-model="amount" id="amount" size="lg" placeholder="Amount" />
                        </b-form-group>
    
    
    
    
    
                        <div class="demo-inline-spacing">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="load || process">
                              <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else> Continue</span> 
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BFormCheckbox, BAvatar, BFormRadio, BForm, BSpinner, BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'

import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,
BFormCheckbox,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },

    watch: {
        ported: function(value){

            console.log(value);
        }
    },


    data() {
        return {
            load: false,
            ported: false,
            userairtime: [],
            selectedairtime: null,
            mainurl: jwtDefaultConfig.mainurl,
            phone: null,
            process: false,
            pin: null,
            amount: null,
        }
    },
    // computed: {
    //    addstyle: function(id) {
    //            return this.selectedairtime == id ? "border: 0.3rem solid green": "papa"
    //     }
    // },
    created() {
        this.getairtim();
    },

    methods: {
        addstyle(id) {
            return this.selectedairtime == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
            this.selectedairtime = id;
        },


        async getairtim() {
            this.load = true;
            await useServices.getairtime().then((result)=> {
                this.userairtime = result.data.data
            });

            this.load = false;

        },




        async processtransaction() {

            this.process = true;
            console.log(this.pin);
            try {
                await useServices.processairtime({
                    network: this.selectedairtime,
                    phone_number: this.phone,
                    ported: this.ported,
                    amount: this.amount,
                 
                }).then((result) => {

  this.process = false;
                    if (result.data.data.status == true) {


                        console.log(result.data.data.data.hash)

                        this.$router.push({name: "transaction", params: { hash: result.data.data.data.hash}});

                        console.log(result.data.data)

                    } else {

                        this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                    }



                });
            } catch (error) {
                  this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;



        }
    },





}
</script>

